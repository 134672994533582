<template>
  <div class="asf-radio" :class="{ 'is-checked': isChecked, 'is-disabled': disabled, 'm-align-top': description }">
    <div class="asf-radio__wrapper">
      <input
        :id="fieldID"
        v-e2e="'radio-button'"
        type="radio"
        :name="name"
        :value="value"
        :form="form"
        :required="required"
        :checked="isChecked"
        :disabled="disabled"
        :aria-required="required"
        :aria-checked="isChecked"
        :aria-disabled="disabled"
        v-bind="$attrs"
        class="asf-radio__input"
        @change="inputHandler"
      />
      <!-- @slot Custom checkmark markup (bind 'isChecked' boolean, 'disabled' boolean -->
      <slot name="content-checkmark" v-bind="{ isChecked, disabled }">
        <div class="asf-radio__checkmark" />
      </slot>
    </div>
    <div class="asf-radio__content">
      <div class="asf-radio__content-inner">
        <label class="asf-radio__label" :for="fieldID" :aria-hidden="labelAriaHidden">
          <slot name="content-label" v-bind="{ label }">
            {{ label }} <template v-if="optionCount">({{ optionCount }})</template>
          </slot>
        </label>
        <!-- @slot Custom description markup (bind 'description' string -->
        <slot name="content-description" v-bind="{ description }">
          <span v-if="description" class="asf-radio__description" :aria-hidden="labelAriaHidden">{{
            description
          }}</span>
        </slot>
        <div v-if="caption" class="asf-radio__caption">
          <slot name="content-caption-message" v-bind="{ caption }">
            <span>{{ caption }}</span>
          </slot>
        </div>
      </div>
      <!-- @slot Custom details markup (bind 'details' string -->
      <slot name="content-details" v-bind="{ details }">
        <span v-if="details" class="asf-radio__details">{{ details }}</span>
      </slot>
    </div>
  </div>
</template>
<script lang="ts">
import { AsfRadioProps } from '@ui/types'

export default defineComponent({
  name: 'AsfRadio',
  model: { prop: 'checked', event: 'change' },
  props: {
    checked: { type: String as PropType<AsfRadioProps['checked']>, default: '' },
    description: { type: String as PropType<AsfRadioProps['description']>, default: '' },
    details: { type: String as PropType<AsfRadioProps['details']>, default: '' },
    value: { type: String as PropType<AsfRadioProps['value']>, default: '' },
    optionCount: { type: Number as PropType<AsfRadioProps['optionCount']>, default: 0 },
    labelAriaHidden: { type: Boolean as PropType<AsfRadioProps['labelAriaHidden']>, default: false },
    ...formElementAttributes
  },
  setup(props: AsfRadioProps, { emit }) {
    const isChecked = computed(() => props.value === props.checked)
    const fieldID = computed(() => `radio-${props.id || props.name}`)
    const inputHandler = () => emit('radioChange', props.value)

    return { isChecked, fieldID, inputHandler }
  }
})
</script>
<style lang="postcss">
@import '@components/atoms/Radio/Radio.css';
</style>
