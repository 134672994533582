import { PropType } from 'vue'
import { AsfFormElementProps, AsfInputTypes } from '@ui/types'

export const formElementAttributes = {
  /** A string specifying an autocomplete for the input control */
  autocomplete: { type: [String, Number] as PropType<AsfFormElementProps['autocomplete']>, default: 'off' },
  /** A string specifying a caption/hint for the input control */
  caption: { type: String as PropType<AsfFormElementProps['caption']>, default: '' },
  /** An attribute which, if present, indicates that the user should not be able to interact with the input. */
  disabled: { type: Boolean as PropType<AsfFormElementProps['disabled']>, default: false },
  /** A string specifying the `<form>` element with which the input is associated. */
  form: { type: String as PropType<AsfFormElementProps['form']>, default: '' },
  /** A string specifying an id attribute for the input control */
  id: { type: String as PropType<AsfFormElementProps['id']>, default: '' },
  /** A string specifying a label for the input control */
  label: { type: String as PropType<AsfFormElementProps['label']>, default: '' },
  /** A string specifying a name for the input control. */
  name: { type: String as PropType<AsfFormElementProps['name']>, default: '' },
  /** An attribute which, if present, indicates that the user must specify a value
   *  for the input before the owning form can be submitted.
   */
  required: { type: Boolean as PropType<AsfFormElementProps['required']>, default: false }
}

export const textFieldTypes: AsfInputTypes[] = [
  'date',
  'datetime-local',
  'email',
  'hidden',
  'month',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'time',
  'url',
  'week'
]
